import uniqBy from 'lodash.uniqby';

/**
 * Extract list of user roles from a given workflow
 * by iterating on the workflow steps
 * @param {*} workflow
 */
export const extractRolesFromWorkflow = (workflow) => {
  return uniqBy(workflow.steps, 'approverRole').map(
    (item) => item.approverRole
  );
};

export const extractRolesFromWorkflows = (workflows) => {
  let steps = [];
  for (const workflow of workflows) {
    steps = steps.contact(workflow.steps);
  }

  return uniqBy(steps, 'approverRole').map((item) => item.approverRole);
};
